import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      const privacyPolicyLink = document.getElementById("privacyPolicyLink");

      privacyPolicyLink.addEventListener("click", function (e) {
        e.preventDefault(); // Prevent default link behavior

        // Open PDF in new tab
        window.open(this.href, "_blank");

        // Trigger download
        const link = document.createElement("a");
        link.href = this.href;
        link.download = "privacy-policy.pdf"; // Set desired filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    });
  });
  return (
    <footer class="bg-[#2E3232] text-white pt-8 px-[20px] md:px-[70px]">
      <div class=" ">
        {/* <!-- Logo and contact for smaller screens --> */}
        <div class="md:hidden">
          {/* <div class="flex flex-col items-center mb-8">
            <img src="./assets/images/logo.svg" alt="" />
          </div> */}
          <div className="flex flex-col gap-[24px]">
            <div className="space-y-3">
              <p class="font-semibold text-[14px]">Address:</p>
              <p class="text-[14px] text-white">
                10780 Westview Dr STE F #76, Houston, TX, USA, 77043
              </p>
              <p class=" text-white text-[14px]">
                3240 E State Street Ext, Hamilton, NJ, USA, 08619
              </p>
            </div>
            <div class="mb-6">
              <p class="font-semibold">Contact:</p>
              <a
                href="mailto:info@universaltech.ai"
                class="text-white underline"
              >
                info@universaltech.ai
              </a>
            </div>
          </div>
        </div>

        <hr className="border-gray-600 mb-[32px] mt-[12px] md:hidden" />

        {/* <!-- Layout for md screens and above --> */}
        <div class="hidden  md:flex justify-between items-start gap-x-8">
          <div>
            <img src="./assets/images/logo.svg" alt="" />
          </div>
          {/* <div>
        <p class="font-bold">Contact:</p>
        <a href="mailto:info@universaltech.ai" class="hover:underline text-white">info@universaltech.ai</a>
      </div> */}
          <div className="flex gap-[24px] justify-end">
            <div className="w-1/3">
              <p class="font-semibold text-[14px]">Address:</p>
              <p class="text-[14px] text-white">
                10780 Westview Dr STE F #76, Houston, TX, USA, 77043
              </p>
            </div>
            <div className="w-1/3">
              <p class="font-semibold text-[14px]">Address:</p>
              <p class="text-[14px] text-white">
                3240 E State Street Ext, Hamilton, NJ , USA, 08619
              </p>
            </div>
            <div>
              <p class="font-semibold text-[14px]">Contact:</p>
              <a
                href="mailto:info@universaltech.ai"
                class="underline text-white text-[14px]"
              >
                info@universaltech.ai
              </a>
            </div>
          </div>
        </div>

        <hr className="border-gray-600 my-4 hidden md:flex" />

        {/* <!-- Copyright and links --> */}
        <div class="text-center flex md:flex-row flex-col gap-[24px] md:gap-0 justify-between text-sm text-white mt-6 pb-[32px]">
          <p>&copy; 2024 Universal Tech. All rights reserved.</p>
          <div class="flex gap-[24px] justify-center">
            <Link
              to="/privacy-policy"
              // target="_blank"
              class="text-white mr-2.5 underline"
              id="privacyPolicyLink"
            >
              Privacy Policy
            </Link>
            <Link
              to={"/terms-of-service"}
              // target="_blank"
              class="text-white mr-2.5 underline"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
