import React, { useRef, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Toolbar, Menu, Link as MuiLink } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useLocation } from "react-router-dom";
import "../index.css";

const serviceSubMenu = [
  {
    title: "Enterprise Application Development and Modernization",
    path: "#enterprise",
  },
  { title: "Data and Intelligence", path: "#data" },
  { title: "Cybersecurity Solutions", path: "#cybersecurity" },
  { title: "IT Managed Services", path: "#it" },
  { title: "SparkForge – Our Innovation Hub", path: "#sparkForge" },
];

function Navbar() {
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [menuToggle, setMenuToggle] = useState(false);
  const [mobileServicesOpen, setMobileServicesOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isTransparent, setIsTransparent] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const menuRef = useRef(null);

  const isPrivacyPolicy = location.pathname === '/privacy-policy';
  const isTermsOfService = location.pathname === '/terms-of-service';

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const viewportHeight = window.innerHeight;
      const isScrollingUp = prevScrollPos > currentScrollPos;

      if (currentScrollPos < viewportHeight) {
        setIsTransparent(true);
        setIsHidden(!isScrollingUp && currentScrollPos > 50);
      } else {
        setIsTransparent(false);
        setIsHidden(!isScrollingUp);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuToggle(false);
        setMobileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    setMobileMenuOpen(true);
    // document.body.style.overflow = 'hidden';
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setMobileMenuOpen(false);
    // document.body.style.overflow = ''; // Restore scrolling
  };

  const handleService = () => {
    setMenuToggle(true);
  };

  const handleMobileServicesToggle = () => {
    setMobileServicesOpen(!mobileServicesOpen);
  };

  return (
    <AppBar
      position="fixed"
      className="h-[70px] flex justify-center"
      style={{
        // background: isTransparent ? "transparent" : "white",
        background: isPrivacyPolicy || isTermsOfService ? "#051367" : (isTransparent ? "transparent" : "white"),
        position: "fixed",
        zIndex: 50,
        transform: isHidden ? "translateY(-100%)" : "translateY(0)",
        transition: "transform 0.3s ease, background-color 0.3s ease",
        boxShadow: isTransparent
          ? "none"
          : "0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters className="md:px-[50px]">
          <div className="flex justify-between flex-row w-[100%]">
            <div>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  textDecoration: "none",
                  background: "transparent",
                }}
                className="pt-2"
              >
                <a href="/">
                  <img
                    src={
                      isTransparent
                        ? "./assets/images/logo.svg"
                        : "./assets/images/bluebanner.svg"
                    }
                    alt="logo"
                    style={{ width: "auto", height: "35px" }}
                  />
                </a>
              </Typography>
            </div>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <a href="/">
                <img
                  src={
                    isTransparent
                      ? "./assets/images/logo.svg"
                      : "./assets/images/bluebanner.svg"
                  }
                  alt="Logo"
                  style={{ width: "auto", height: "35px" }}
                />
              </a>
            </Typography>
            <div className="flex flex-row items-center pt-3">
              <Box
                sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                className="gap-5"
              >
                <p
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                  style={{ color: isTransparent ? "#FFF" : "#051367" }}
                  className="text-[16px] font-medium"
                >
                  <a href="#about">About Us</a>
                </p>
                <div className="flex" onMouseLeave={() => setMenuToggle(false)}>
                  <p
                    sx={{ my: 2, color: "white", display: "flex" }}
                    style={{ color: isTransparent ? "#FFF" : "#051367" }}
                    className="text-[16px] font-medium pb-3"
                    onMouseEnter={handleService}
                  >
                    Services
                    <ExpandMoreIcon className="flex align-top ml-1" />
                  </p>
                  {menuToggle && (
                    <div className="flex flex-col absolute bg-[#FFFFFF] mt-[31px] pt-[20px] p-[12px] shadow-2xl z-50 rounded-lg gap-[4px]">
                      {serviceSubMenu.map((item) => (
                        <a key={item.title} href={`/${item.path}`}>
                          <p
                            className="p-1 text-[#051367] menu-lists text-[16px] font-medium leading-[150%]"
                            onClick={() => setMenuToggle(false)}
                          >
                            {item.title}
                          </p>
                        </a>
                      ))}
                    </div>
                  )}
                </div>
                <p
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                  style={{ color: isTransparent ? "#FFF" : "#051367" }}
                  className="text-[16px] font-medium"
                >
                  <a href="#innovation">Al Powered Innovation</a>
                </p>
              </Box>
            </div>
          </div>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              //   color="white"
              style={{ color: isTransparent ? "#FFF" : "#051367" }}
            >
              <MenuIcon color="white" />
              {/* background: isTransparent ? "transparent" : "white", */}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              open={mobileMenuOpen}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiPaper-root": {
                  width: "100%",
                  height: "100vh",
                  maxWidth: "100%",
                  left: "0 !important",
                  right: "0 !important",
                  top: "0 !important",
                  position: "fixed",
                  overflowY: "auto",
                  transition: "transform 0.3s ease-in-out",
                  transform: mobileMenuOpen
                    ? "translateX(0)"
                    : "translateX(100%)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  padding: "16px",
                  backgroundColor: "white",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <img
                    src="./assets/images/bluebanner.svg"
                    alt="logo"
                    style={{ width: "auto", height: "35px" }}
                  />
                  <IconButton
                    size="large"
                    aria-label="close menu"
                    onClick={handleCloseNavMenu}
                    color="inherit"
                  >
                    <CloseIcon className="text-[#051367]" />
                  </IconButton>
                </Box>
                <Box
                  sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
                >
                  <MuiLink
                    href="#about"
                    onClick={handleCloseNavMenu}
                    sx={{ color: "#051367", textDecoration: "none" }}
                  >
                    <MenuItem>
                      <p className="font-medium">About Us</p>
                    </MenuItem>
                  </MuiLink>
                  <div
                    className="flex justify-between items-center"
                    onClick={handleMobileServicesToggle}
                  >
                    <div className="flex">
                      <MenuItem className="flex">
                        <p
                          sx={{
                            color: "#051367",
                            display: "flex",
                            width: "100%",
                          }}
                          className="font-medium text-[#051367] flex "
                        >
                          Services
                        </p>
                      </MenuItem>
                    </div>
                    <div className="flex">
                      {mobileServicesOpen ? (
                        <ExpandLessIcon className="text-[#051367] flex" />
                      ) : (
                        <ExpandMoreIcon className="text-[#051367] flex" />
                      )}
                    </div>
                  </div>
                  {mobileServicesOpen &&
                    serviceSubMenu.map((item) => (
                      <MuiLink
                        key={item.title}
                        href={item.path}
                        onClick={handleCloseNavMenu}
                        sx={{ color: "#051367", textDecoration: "none" }}
                      >
                        <p
                          className="p-3 text-[black] font-medium"
                          sx={{ paddingLeft: 4 }}
                        >
                          {item.title}
                        </p>
                      </MuiLink>
                    ))}
                  <MuiLink
                    href="#innovation"
                    onClick={handleCloseNavMenu}
                    sx={{ color: "#051367", textDecoration: "none" }}
                  >
                    <MenuItem>
                      <p className="font-medium">AI Powered Innovation</p>
                    </MenuItem>
                  </MuiLink>
                </Box>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
