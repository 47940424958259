import React from "react";

const Home = () => {
  return (
    <div>
      <div className="bg-cover bg-center flex flex-col px-[20px] sm:px-[24px] md:px-[64px] pt-[16px] background-default">
        {/* <div className="flex justify-between items-center">
          <img src="./assets/images/logo.svg" alt="" />
          <div className="relative group">
            <h1 className="text-[16px] font-semibold text-white">Contact Us</h1>
            <span className="absolute left-0 bottom-0 w-full h-[2px] bg-white transform scale-x-0 transition-transform duration-300 origin-left group-hover:scale-x-100"></span>
          </div>
        </div> */}
        <div className=" bg-opacity-50 py-16">
          <div className="">
            <h1 className="text-white py-[64px] md:py-[81px] text-[44px] md:text-[70px] lg:text-[88px] font-semibold md:font-medium mb-4  leading-[120%]">
              Elevating Enterprise Agility with Digital Innovation
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
