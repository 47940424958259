import React from "react";

const AIInnovation2 = () => {
  const cards = [
    {
      title: "Machine ",
      title2: "Learning",
      description:
        "Unlock the potential of your data with advanced machine learning algorithms that automate and optimize business processes, providing actionable insights and enhancing decision-making.",
      image: "./assets/images/m11.png",
    },
    {
      title: "Natural Language ",
      title2: "Processing",
      description:
        "Transform the way you interact with customers and data. Our NLP solutions enable sophisticated text analysis, sentiment detection, and seamless human-computer interactions, improving communication.",
      image: "./assets/images/m12.png",
    },
    {
      title: "Computer ",
      title2: "Vision",
      description:
        "Revolutionize your operations with intelligent image and video analysis. Our computer vision solutions provide accurate detection, recognition, and classification capabilities for a wide range of applications.",
      image: "./assets/images/m13.png",
    },
    {
      title: "Generative",
      title2: "AI",
      description:
        "Innovate with AI-driven creativity. Our generative AI solutions help you create unique content, design new products, and develop creative strategies, keeping you ahead in a competitive landscape.",
      image: "./assets/images/m14.png",
    },
  ];

  return (
    <div className=" bg-[#E4F1FE] py-[64px] md:py-[112px] " id="innovation">
      <div className="px-[20px] sm:px-[24px] md:px-[64px]  flex flex-col md:flex-row gap-[24px] md:gap-[80px] mb-[24px] md:mb-[80px]">
        <div className="flex flex-col w-full md:w-1/2 gap-[16px]">
          <h4 className="text-[#051367] text-[16px] md:text-[20px] font-semibold">
            AI Powered Innovation
          </h4>
          <h1 className="text-[#2E3232] font-extrabold text-[48px] leading-[57px]">
            Harnessing AI for endless innovation
          </h1>
        </div>
        <div className="flex w-full md:w-1/2">
          <p className="text-[#2E3232] font-normal text-[18px] leading-[160%] lg:leading-7">
            Our solutions are designed to seamlessly integrate with your
            existing enterprise systems, ensuring scalability, security, and
            compliance. Whether you're looking to automate processes, enhance
            customer experiences, or uncover data-driven insights, our AI
            experts develop tailored, production-grade solutions that can meet
            your specific business needs. We also provide guidance on ethical AI
            implementation and governance.
          </p>
        </div>
      </div>

      <div className="w-full overflow-x-auto md:overflow-x-visible custom-scrollbar pr-[19px] pl-[20px] sm:px-[24px] md:px-[64px] ">
        <div className="flex md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-[16px] pb-4 md:pb-0 mobile-scroll-space">
          {cards.map((card, index) => (
            <div
              key={index}
              className="relative w-80 md:w-auto h-[440px] flex-shrink-0 overflow-hidden rounded-lg"
            >
              <img
                src={card.image}
                alt={card.title}
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
              <div className="absolute top-[120px] px-[24px] text-white ">
                <h3 className="font-bold text-[28px] md:text-[28px] lg:text-[24px] leading-[130%]">
                  {card.title}
                </h3>
                <h3 className="font-bold text-[28px] md:text-[28px] lg:text-[24px] leading-[130%] mb-[16px]">
                  {card.title2}
                </h3>
                <p className="text-[16px] leading-[25px]">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AIInnovation2;
